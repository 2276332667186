import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "our-history"
};
import OurHistoryBanner from "@/views/about/our-history-module/our-history-banner.vue";
import OurHistoryWheel from "@/views/about/our-history-module/our-history-wheel.vue";
export default {
  __name: 'our-history',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(OurHistoryBanner), _createVNode(OurHistoryWheel)]);
    };
  }
};